<!--
File: Roles.vue
Description: show list of roles entered in the DB.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px" :placeholder="$t('label.search_records')"
              v-model="searchQuery"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="addRole"> {{ $t('buttons.add') }} </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-table :value="searchedData" @md-selected.native="onSelect" :md-sort.sync="currentSort" md-fixed-header
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell :md-label="$t('stdCols.name')" md-sort-by="role_key">
            {{ item.role_key }}
          </md-table-cell>
          <md-table-cell :md-label="$t('stdCols.description')" md-sort-by="description">
            {{ item.role_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('rbac.permissions_count')" md-sort-by="permission_count" md-numeric>
            <a v-if="item.permission_count > 0" href='#' @click.stop.prevent='showPermissions(item.role_key)'>
              {{ item.permission_count }}</a>
              <span v-else>0</span>
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.active')" md-sort-by="active">
            {{ item.active ? $t('buttons.yes') : $t('buttons.no') }}
          </md-table-cell>
          <md-table-cell :md-label="$t('tables.actions')">
            <div>
              <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                @click.native="handleEdit(item.role_id)">
                <md-icon>edit</md-icon>
              </md-button>
              <md-button v-if="amIAdmin" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                @click.stop.prevent="handleDelete(item)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>

    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
  </md-card>
</template>
<script>
  import { mapState, mapGetters } from 'vuex'
  import { Pagination } from '@/components'
  import { customSortMixin } from '@/mixins/customSortMixin'
  import messagesMixin from '@/mixins/messagesMixin'
  import Fuse from 'fuse.js'

  export default {
    name: 'roles-list',
    mixins: [customSortMixin, messagesMixin],

    data() {
      return {
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['role_key', 'role_description'],
        currentSort: 'role_key',
        currentSortOrder: 'asc',
        fuseSearch: null,
      }
    },

    components: {
      Pagination
    },

    mounted() {
      this.reloadData()
    },

    methods: {
      async reloadData() {
        await this.$store.dispatch('LOAD_ROLES_LIST')
        this.fuseSearch = new Fuse(this.rolesList, { keys: this.propsToSearch, threshold: 0.3 })
      },

      showPermissions(role) {
        console.log('role', role)
        const hist = {
          selectedRole: role,
          perPage: 10,
          currentPage: 1
        }
        this.$store.dispatch('SAVE_HISTORY', { data: hist, for: 'permissions' })
        this.$store.dispatch('SET_USE_HISTORY')
        this.$router.push('/settings/permissions')
      },

      handleEdit(id) {
        this.$router.push(`/settings/roles/upd/${id}`)
      },

      addRole() {
        this.$router.push(`/settings/roles/add`)
      },

      async handleDelete(item) {
        const result = await this.deleteConfirmation(item.role_key)
        if (!result.value) { return }

        this.$store.dispatch('DEL_ROLE', item.role_id).then(
          () => {
            this.reloadData()
            this.deletedMessage(item.role_key)
          },
          (err) => {
            this.errorMessage(err.message)
          }
        )
      }
    },

    computed: {
      ...mapState({
        rolesList: (state) => state.RBAC.rolesList,
      }),

      ...mapGetters(['amIAdmin']),

      rolesCount() {
        return this.rolesList.length
      },

      to() {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },

      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },

      total() {
        return this.searchQuery ? this.searchedData.length : this.rolesCount
      },

      searchedData() {
        if (!this.rolesCount) {
          return []
        } else {
          return (!this.searchQuery) ? this.rolesList : this.fuseSearch.search(this.searchQuery)
        }
      }
    },

    watch: {
    }
  }
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-card {
  margin: 0px 0;
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>